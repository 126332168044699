import { useRef } from "react";
import Image from "next/image";
import styled from "styled-components";
import { rem } from "polished";
import Dialog from "@/components/Dialog";
import Text from "@/components/Text";
import ButtonBlank from "@/components/ButtonBlank";
import Message from "@/components/Message";
import closeIcon from "@/assets/icon-close.svg";
import graphic from "@/assets/header-consultation-graphic.svg";
import { useGlobalContext } from "@/lib/global-context";
import Contacts from "@/blocks/Footer/Contacts";
import ContactBlockAsync from "@/blocks/ContactBlockAsync";

const StyledDialog = styled(Dialog)`
  @media ${({ theme }) => theme.devices.laptop} {
    section {
      padding: ${rem(32)} 0;
      min-height: ${rem(740)};
    }
  }
  @media ${({ theme }) => theme.devices.site} {
    section {
      min-height: ${rem(600)};
    }
  }
`;

const Layout = styled.div`
  position: sticky;
  top: 0;
  grid-column: 3 / 4;
  grid-row: 1;

  display: inherit;
  grid-template-columns: auto 8fr;
  grid-column-gap: ${rem(20)};
  grid-row-gap: ${rem(20)};

  @media ${({ theme }) => theme.devices.laptop} {
    /* layout: B2 (modified) */
    grid-template-columns: auto 36fr 16fr 28fr 36fr 8fr auto;
    grid-row-gap: 0;
  }
`;

const Background = styled.div`
  background-color: ${({ theme }) => theme.colors.yellow};
  position: absolute;
  top: ${rem(-40)};
  right: ${rem(-320)};
  bottom: ${rem(-20)};
  left: ${rem(-80)};
  z-index: -1;

  @media ${({ theme }) => theme.devices.laptop} {
    top: ${rem(-100)};
    right: ${rem(-20)};
    bottom: ${rem(-100)};
    left: ${rem(-10000)};
  }
`;

const ContentWrapper = styled.div`
  position: relative;

  h1 {
    position: relative;
    margin: 0;
  }

  p {
    position: relative;
    margin-top: ${rem(32)};
  }

  @media ${({ theme }) => theme.devices.laptop} {
    grid-column: 2;

    p {
      margin-top: ${rem(40)};
    }
  }
`;

const FormWrapper = styled.div`
  grid-column: 1 / 3;
  margin-top: ${rem(16)};

  @media ${({ theme }) => theme.devices.laptop} {
    grid-column: 4 / 6;
    margin-top: 0;
  }
`;

const ContactBlockStyled = styled(ContactBlockAsync)`
  display: flex;
  justify-content: center;

  & > div {
    width: 100%;
  }
`;

const CloseButton = styled(ButtonBlank)`
  grid-column: 2;
  grid-row: 1;
  align-self: start;
  justify-self: end;
  width: ${rem(36)};
  height: ${rem(36)};

  color: ${({ theme }) => theme.colors.grey1};
  position: relative;
  z-index: 2;

  &:hover {
    color: ${({ theme }) => theme.colors.offsetBlack};
  }

  @media ${({ theme }) => theme.devices.laptop} {
    grid-column: 6;
    margin-top: ${rem(9)};
  }
`;

const Illustration = styled.div`
  width: ${rem(481)};
  height: ${rem(240)};
  display: none;

  @media ${({ theme }) => theme.devices.laptop} {
    display: block;
    position: absolute;
    right: ${rem(-20)};
    bottom: ${rem(-32)};
  }
`;

function CloseWidget({ onClose }) {
  return (
    <Message
      id="close"
      fallbackText="Close"
      render={(message) => (
        <CloseButton
          onClick={onClose}
          title={message}
          data-testid="contact-overlay-close"
        >
          <Image src={closeIcon} layout="fill" alt="Close" />
        </CloseButton>
      )}
    />
  );
}

export default function DialogScheduleCall({ onClose = () => {} }) {
  const nameInputRef = useRef();
  const site = useGlobalContext();
  const { contacts } = site?.acfOptionsFooter?.footerFields ?? {};

  return (
    <StyledDialog
      align="top"
      delay={0}
      onOpen={() => {
        if (nameInputRef.current) {
          nameInputRef.current.focus();
        }
      }}
    >
      <Layout>
        <ContentWrapper>
          <Background />
          <Illustration>
            <Image alt="illustration" src={graphic} layout="responsive" />
          </Illustration>
          <h1>
            <Message id="schedule_call_title" fallbackText="How can we help?" />
          </h1>
          <Text light>
            <Message
              id="schedule_call_will_discuss"
              fallbackText="Whether you’re looking for an end-to-end solution, or a natural extension to your existing in-house setup—we’ve got you covered!"
            />
          </Text>
          {contacts?.links && <Contacts contacts={contacts} />}
        </ContentWrapper>
        <FormWrapper>
          <ContactBlockStyled id="dialogScheduleCall" />
        </FormWrapper>
        <CloseWidget onClose={onClose} />
      </Layout>
    </StyledDialog>
  );
}
