import React, { useState, useRef, useEffect } from "react";
import { getCookiePreferences } from "@/components/DialogCookies";
import styled from "styled-components";
import Button from "@/components/Button";
import { useGlobalContext } from "@/lib/global-context";
import Typography from "@/components/Typography";
import theme from "@/styles/theme";
import ButtonBlank from "@/components/ButtonBlank";
import { AnimatePresence, motion } from "framer-motion";
import { rem } from "polished";

const DialogOverlay = styled.div`
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const StyledButton = styled(ButtonBlank)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: ${rem(16)} 0;
  background-color: transparent;
  border: none;
  cursor: default;
  width: 100%;
  gap: ${rem(10)};
  font-size: ${({ theme }) => theme.fontsMobile.body.fontSize};
  font-weight: ${({ theme }) => theme.fontsMobile.body.fontWeight};
  line-height: ${({ theme }) => theme.fontsMobile.body.lineHeight};

  svg {
    margin-top: ${rem(6)};
  }

  @media ${({ theme }) => theme.devices.tablet} {
    font-size: ${({ theme }) => theme.fonts.body.fontSize};
    font-weight: ${({ theme }) => theme.fonts.body.fontWeight};
    line-height: ${({ theme }) => theme.fonts.body.lineHeight};
  }
`;

const DialogContainer = styled.div`
  background-color: ${theme.colors.white};
  width: 90%;
  max-width: ${rem(800)};
  border-radius: ${rem(8)};
  padding: ${rem(32)} ${rem(28)};

  @media ${({ theme }) => theme.devices.mobile} {
    overflow: auto;
    max-height: 90vh;
  }
`;

const CategorySection = styled.div`
  background-color: ${theme.colors.white};
`;

const CategoryHeader = styled.div`
  display: flex;
  align-items: center;
`;

const CategoryContent = styled(AnimatePresence)``;

const ToggleSwitch = styled.label`
  position: relative;
  width: ${rem(66)};
  height: ${rem(34)};

  @media ${({ theme }) => theme.devices.mobile} {
    padding-right: ${rem(30)};
  }
`;

const Title = styled(Typography)`
  margin-bottom: ${rem(28)};
  color: ${theme.colors.offsetBlack};
`;

const Description = styled(Typography)`
  color: ${theme.colors.grey1};
  margin-bottom: ${rem(20)};
`;

const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${theme.colors.grey4};
  transition: 0.5s;
  border-radius: ${rem(34)};
  &:before {
    position: absolute;
    content: "";
    height: ${rem(26)};
    width: ${rem(26)};
    left: ${rem(4)};
    bottom: ${rem(4)};
    background-color: ${theme.colors.white};
    transition: 0.5s;
    border-radius: 50%;
  }
  input:checked + & {
    background-color: ${theme.colors.videoOffsetBlack};
  }
  input:focus + & {
    box-shadow: 0 0 ${rem(1)} ${theme.colors.videoOffsetBlack};
  }
  input:checked + &:before {
    transform: translateX(26px);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${rem(16)};
  gap: ${rem(16)};

  > button {
    @media ${({ theme }) => theme.devices.tablet} {
      min-width: ${rem(160)};
    }
  }
`;

const Content = styled(motion.div)``;

const arrowMotion = {
  down: {
    rotate: 90,
    ...theme.motion.standard,
  },
  right: {
    rotate: 0,
    ...theme.motion.standard,
  },
};

function Arrow({ down }) {
  return (
    <motion.svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      focusable="false"
      initial="right"
      animate={down ? "down" : "right"}
      variants={arrowMotion}
    >
      <path
        d="M13.207 8.472l-7.854 7.854-0.707-0.707 7.146-7.146-7.146-7.148 0.707-0.707 7.854 7.854z"
        fill="currentColor"
      />
    </motion.svg>
  );
}

export default function DialogCookiesPreferences({ onSave }) {
  const site = useGlobalContext();
  const [openAccordion, setOpenAccordion] = useState(null);
  const dialogRef = useRef(null);
  const firstFocusableElementRef = useRef(null);
  const lastFocusableElementRef = useRef(null);

  const { modalTitle, modalContent, cookieGroups } =
    site?.acfOptionsCookiePolicy?.cookiePolicyFields ?? {};

  const [preferences, setPreferences] = useState(
    getCookiePreferences() || {
      marketing: true,
      analytics: true,
      personalization: true,
    }
  );

  const contentMotion = {
    collapsed: {
      height: 0,
      opacity: 0,
      transition: {
        duration: 0.15,
      },
    },
    open: {
      height: "auto",
      opacity: 1,
      ...theme.motion.standard,
    },
  };

  const toggleAccordion = (index) => {
    if (openAccordion === index) {
      setOpenAccordion(null);
    } else {
      setOpenAccordion(index);
    }
  };

  const handleChange = (e) => {
    setPreferences({ ...preferences, [e.target.name]: e.target.checked });
  };

  const handleAcceptAll = () => {
    const allAcceptedPreferences = {
      marketing: true,
      analytics: true,
      personalization: true,
    };
    setPreferences(allAcceptedPreferences);
    onSave(allAcceptedPreferences);
  };

  useEffect(() => {
    const focusableElements = dialogRef.current.querySelectorAll(
      "button, [href], input"
    );
    if (focusableElements.length > 0) {
      firstFocusableElementRef.current = focusableElements[0];
      lastFocusableElementRef.current =
        focusableElements[focusableElements.length - 1];
    }

    const handleKeyDown = (e) => {
      if (e.key === "Tab") {
        if (e.shiftKey) {
          if (document.activeElement === firstFocusableElementRef.current) {
            lastFocusableElementRef.current.focus();
            e.preventDefault();
          }
        } else {
          if (document.activeElement === lastFocusableElementRef.current) {
            firstFocusableElementRef.current.focus();
            e.preventDefault();
          }
        }
      }
    };
    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <DialogOverlay ref={dialogRef}>
      <DialogContainer>
        <Title variant={"h3"} render={modalTitle} />
        <Description variant={"body"} component="div" render={modalContent} />
        {Object.entries(preferences).map(([key, value], index) => {
          const group = cookieGroups.find((g) =>
            g.title.toLowerCase().startsWith(key)
          );
          return (
            <CategorySection key={key}>
              <CategoryHeader>
                <StyledButton
                  as="button"
                  tabIndex={0}
                  aria-expanded={openAccordion === index}
                  onClick={() => toggleAccordion(index)}
                >
                  <Arrow down={openAccordion === index} />
                  {group && group.title}
                </StyledButton>
                <ToggleSwitch>
                  <input
                    type="checkbox"
                    name={key}
                    checked={value}
                    onChange={handleChange}
                  />
                  <Slider />
                </ToggleSwitch>
              </CategoryHeader>
              <CategoryContent>
                {openAccordion === index && (
                  <Content
                    key={key}
                    initial="collapsed"
                    animate="open"
                    exit="collapsed"
                    variants={contentMotion}
                  >
                    <Typography
                      variant="smallBody"
                      component="div"
                      colorVariant="textLight"
                      render={group && group.content}
                    />
                  </Content>
                )}
              </CategoryContent>
            </CategorySection>
          );
        })}
        <ButtonContainer>
          <Button appearance="secondary" onClick={() => onSave(preferences)}>
            Allow selection
          </Button>
          <Button appearance="primary" onClick={handleAcceptAll}>
            Accept all
          </Button>
        </ButtonContainer>
      </DialogContainer>
    </DialogOverlay>
  );
}
